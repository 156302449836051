import React, { FC } from 'react';
import './FilterList.scss';
import { IFilterListProps } from '../../../types/types';

const NewList: FC<IFilterListProps> = (props) => {
    return (
        <div data-testid="filter-list" className="filter-list">
            <div className="filter-list__body">
                <div className="filter-list__chose">
                    <label className="filter-list__label">
                        <input
                            key={props.id}
                            onClick={props.handleCheckChieldElement}
                            type="checkbox"
                            checked={props.isChecked}
                            value={props.value}
                            className="filter-list__check"
                        />
                        <span className="filter-list__checkmark"></span>
                        {props.value}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default NewList;
